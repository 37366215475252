import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { App } from '/App'
import { EventEmitter } from '/Utils/EventEmitter'
import { Utils } from '../Utils/Utils'
import Ease from '../Utils/Ease'

/*
 In fixed mode, the camera is fixed and the scene is rotating
 */

export class Camera extends EventEmitter {
    constructor(controllable) {
        super()

        this.controllable = typeof controllable === "boolean" ? controllable : true

        this.app = null
        this.instance = null
        this.fake = null
        this.controls = null

        this.resizeHandlerBound = this.resizeHandler.bind(this)
        this.updateBound = this.update.bind(this)

        this.azimuthAnimDuration = null
        this.polarAnimDuration = null
        
        this.targetAzimuth = null
        this.targetPolar = null
        
        this.azimuthAnimStartTime = null
        this.polarAnimStartTime = null
        
        this.rotationSpeed = Math.PI / 2 // 90° / s
        // this.rotating = false

        this.init()
    }

    init() {
        this.app = new App()

        this.instance = new THREE.PerspectiveCamera(35, this.app.renderSize.aspect, 0.01, 100)
        // this.instance.position.set(0, 0, 1)
        this.fake = this.instance.clone()
        
        if (this.controllable) {
            this.controls = new OrbitControls(this.instance, this.app.canvas)
            this.controls.enablePan = false
            this.controls.enableDamping = true
            this.controls.dampingFactor = 0.15

            if (this.app.debug.active) {
                const cf = this.app.debug.ui.addFolder("Camera").close()
                cf.add(this.controls, "enableDamping", true)
                cf.add(this.controls, "dampingFactor", 0, 0.2, 0.01)
                cf.add(this.controls, "autoRotate", false).name("Auto rotation")
            }
        }
        
        this.app.renderSize.on('resize', this.resizeHandlerBound)
    }

    setPositionZ(z) {
        this.instance.position.z = this.fake.position.z = z

        this.controls.maxDistance = Math.max(z, this.controls.maxDistance)
        this.controls.minDistance = Math.min(z, this.controls.minDistance)
    }

    // setRadius(radius) {
    //     const cPos = this.instance.position
    //     const sPos = Utils.cartesianToSpherical(cPos.x, cPos.y, cPos.z)
    //     this.instance.position.copy(Utils.sphericalToCartesian(radius, sPos.azimuth, sPos.polar))
    // }

    setLimits(minDistance, maxDistance, minPolarAngle, maxPolarAngle) {
        this.controls.minDistance = minDistance
        this.controls.maxDistance = maxDistance

        this.controls.minPolarAngle = minPolarAngle
        this.controls.maxPolarAngle = maxPolarAngle
    }

    rotateToAzimuth(targetAzimuth, duration) {
        this.targetAzimuth = targetAzimuth
        this.azimuth0 = this.controls.getAzimuthalAngle()
        this.azimuthRotation = Utils.differenceBetweenAngles(this.azimuth0, this.targetAzimuth)
        this.azimuthAnimDuration = typeof duration === "number" ? duration : 1.2
        this.azimuthAnimStartTime = null

        console.log(`Camera :: azimuth animation from ${this.azimuth0} to ${this.targetAzimuth} (diff : ${this.azimuthRotation} in ${this.azimuthAnimDuration}s)`)
    }

    rotateToPolar(targetPolar, duration) {
        // Convert from [-π/2, π/2] to [π/2, 0]
        this.targetPolar = Math.PI / 2 - targetPolar
        this.polar0 = this.controls.getPolarAngle()
        this.polarRotation = Utils.differenceBetweenAngles(this.polar0, this.targetPolar)
        this.polarAnimDuration = typeof duration === "number" ? duration : 1.2
        this.polarAnimStartTime = null

        console.log(`Camera :: azimuth animation from ${this.polar0} to ${this.targetPolar} (diff : ${this.polarRotation} in ${this.polarAnimDuration}s)`)
    }

    updateAzimuth(t) {
        let pos = this.instance.position
        // console.log(`Camera :: camera position BEFORE ${pos.x}, ${pos.z}`)
        
        const spherical = Utils.cartesianToSpherical(pos.x, pos.y, pos.z)
        // console.log(`Camera :: az: ${spherical.azimuth}`)
                
        const currentAzimuth = this.azimuth0 + Ease.bezier(t) * this.azimuthRotation 
        // const deg = 180 / Math.PI * currentAzimuth
        // console.log(`Camera :: ${t.toFixed(2)} (rotation : ${rotation}) current az ${currentAzimuth} (from ${this.azimuth0} to ${this.targetAzimuth})`)
        
        this.instance.position.copy(Utils.sphericalToCartesian(spherical.radius, currentAzimuth, spherical.polar))
        // pos = this.instance.position
        // console.log(`Camera :: camera position AFTER  ${pos.x}, ${pos.z}`) 
    }

    updatePolar(t) {
        let pos = this.instance.position
        // console.log(`Camera :: camera position BEFORE ${pos.x}, ${pos.z}`)
        
        const spherical = Utils.cartesianToSpherical(pos.x, pos.y, pos.z)
        // console.log(`Camera :: az: ${spherical.azimuth}`)
                
        const currentPolar = this.polar0 + Ease.bezier(t) * this.polarRotation 
        // const deg = 180 / Math.PI * currentPolar
        // console.log(`Camera :: ${t.toFixed(2)} (rotation : ${rotation}) current az ${currentAzimuth} (from ${this.azimuth0} to ${this.targetAzimuth})`)
        
        this.instance.position.copy(Utils.sphericalToCartesian(spherical.radius, spherical.azimuth, currentPolar))
        // pos = this.instance.position
        // console.log(`Camera :: camera position AFTER  ${pos.x}, ${pos.z}`)
      
    }

    updateAzimuthToTarget(elapsed) {
        if (this.azimuthAnimStartTime !== null) {
            const t = Math.min(1, (elapsed - this.azimuthAnimStartTime) / this.azimuthAnimDuration)
            this.updateAzimuth(t)

            if (t === 1) {
                this.azimuthAnimStartTime = null
                this.targetAzimuth = null
            }   
        }
    }

    updatePolarToTarget(elapsed) {
        if (this.polarAnimStartTime !== null) {
            const t = Math.min(1, (elapsed - this.polarAnimStartTime) / this.polarAnimDuration)
            this.updatePolar(t)

            if (t === 1) {
                this.polarAnimStartTime = null
                this.targetPolar = null
            }   
        }
    }

    update(event) {
        const sPos = new THREE.Spherical().setFromVector3(this.instance.position)
        this.fake.position.z = sPos.radius

        if (this.controls) {
            this.controls.update()
        }

        if (this.targetAzimuth !== null) {
            if (this.azimuthAnimStartTime === null) {
                this.azimuthAnimStartTime = event.elapsed
            }

            this.updateAzimuthToTarget(event.elapsed)
        }
            
        
        if (this.targetPolar !== null) {

            if (this.polarAnimStartTime === null) {
                this.polarAnimStartTime = event.elapsed
            }

            this.updatePolarToTarget(event.elapsed)
        }
    }

    resizeHandler(info) {
        const { aspect } = info

        this.instance.aspect = aspect
        this.instance.updateProjectionMatrix()
        
        this.fake.aspect = aspect
        this.fake.updateProjectionMatrix()

        this.fovWidth = this.fovHeight * aspect
    }

    destroy() {
        this.app.animationLoop.off('resize')
        this.updateBound = null

        this.app.renderSize.off('resize')
        this.resizeHandlerBound = null

        this.fake = null
        this.instance = null

        this.app = null
    }
}