import { Camera } from './Core/Camera.js'
import { Renderer } from './Core/Renderer.js'
import { AnimationLoop } from './Utils/AnimationLoop.js'
import { RenderSize } from './Utils/RenderSize.js'
import { AssetManager } from './Loading/AssetManager.js'
import { Debug } from './Utils/Debug.js'
import { Memory } from './Utils/Memory.js'
import Lights from './World/Lights.js'
import Ground from './World/Ground.js'
import UIManager from './UI/UIManager.js'
import State from './State.js'
import Opinel from './World/Opinel.js'

import assets from "/assets.js"

import * as THREE from 'three'
import gsap from 'gsap'
import RoomEnvironmentGenerator from './Utils/RoomEnvironmentGenerator.js'

let instance = null

export class App  {
    constructor(params) {
        if (instance !== null) {
            return instance
        }

        instance = this


        // Init app config
        this.config = {}
        this.config.noSplashScreen = typeof params.noSplashScreen === 'boolean' ? params.noSplashScreen : false
        
        this.canvas = params.canvas

        this.updateBound = this.update.bind(this)
        this.assetManagerReadyHandlerBound = this.assetManagerReadyHandler.bind(this)
        this.onOpinelOpenedBound = this.onOpinelOpened.bind(this)
        this.onOpinelClosedBound = this.onOpinelClosed.bind(this)
        this.openKnifeBound = this.openKnife.bind(this)
        this.closeKnifeBound = this.closeKnife.bind(this)
        this.onChangeMaterialBound = this.onChangeMaterial.bind(this)
        this.onToggleOpenCloseBound = this.onToggleOpenClose.bind(this)
        this.ontouchendBound = this.ontouchend.bind(this)

        this.ready = false

        this.debug = new Debug()
        
        this.animationLoop = new AnimationLoop()
        
        this.renderSize = new RenderSize()
        this.renderer = new Renderer()

        const reg = new RoomEnvironmentGenerator(this.renderer.instance, 512)
        this.envmap = reg.generateEnvMap()
        // reg.exportEnvMapToJPG()
        reg.destroy()

        this.scene = new THREE.Scene()

        this.camera = new Camera()
        const cameraCenterAngle = Math.PI / 4
        const cameraDeltaAngle = Math.PI / 4 
        this.camera.setLimits(4, 50, cameraCenterAngle, cameraCenterAngle + cameraDeltaAngle)
        // const z0 = 8 * 1.78 / this.renderSize.aspect 
        // this.camera.setPositionZ(z0)

        const setCameraZ = (size) => {
            const { aspect } = size
            const pos = 8 * 1.78 / aspect            
            this.camera.setPositionZ(pos)
            console.warn(`camera z : ${this.camera.instance.position.z}`)

            if (this.scene.fog) {
                this.scene.fog.near = this.camera.instance.position.z * 8
                this.scene.fog.far = this.camera.instance.position.z * 10
            }
        }
        setCameraZ({aspect: this.renderSize.aspect})
        this.renderSize.on('resize', setCameraZ)

        this.lastTap = 0
        this.canvas.addEventListener('touchend', this.ontouchendBound)
        this.canvas.addEventListener("dblclick", (event) => {
            this.toggleKnife()
        });

        this.ui = null
        this.wasShowingFrontUI = true

        this.state = new State()
        
        this.worldGroup = null
        this.ground = null
        this.opinel = null
    
        this.assetManager = new AssetManager(assets)
        this.assetManager.on('ready', this.assetManagerReadyHandlerBound)
        this.assetManager.load()

        this.animationLoop.start()
    }

    assetManagerReadyHandler() {
        this.initScene()        
    }

    
    ontouchend = (event) => {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - this.lastTap;

        if (tapLength < 300 && tapLength > 0) {
            this.toggleKnife()
        }

        this.lastTap = currentTime;
    };
    
    initScene() {
        console.log(`App :: setup the scene`)

        this.lights = new Lights()
        this.scene.add(this.lights.instance)

        this.scene.background = this.assetManager.items.restaurant
        this.scene.backgroundBlurriness = 0.4
        this.scene.backgroundIntensity = 0.4

        if (this.debug.active) {
            const bf = this.debug.ui.addFolder('Background').close()
            bf.add(this.scene, 'backgroundBlurriness', 0, 1, 0.01).name('blur')
            bf.add(this.scene, 'backgroundIntensity', 0, 1, 0.01).name('intensity')
        }
        
        this.worldGroup = new THREE.Group()
        // this.worldGroup = new THREE.Scene()
        console.warn('try making worldgroup a scene for background consistency')

        // const background = new THREE.Mesh(new SphereGeometry(1, 32, 32), new MeshBasicMaterial({side:THREE.BackSide, depthTest:false, depthWrite:false, map:this.assetManager.items.restaurant}))
        // background.scale.set(1,1,1).multiplyScalar(30)
        // background.renderOrder = 1
        // this.worldGroup.add(background)
        
        this.scene.add(this.worldGroup)

        this.scene.fog = new THREE.Fog(0x000000, -5, 0)

        // Create 3D world objects and add them to the world group
        this.ground = new Ground()
        this.ground.instance.position.set(0, -1.5, 0)
        this.ground.instance.material.opacity = 0
        this.worldGroup.add(this.ground.instance)
        
        this.opinel = new Opinel()
        this.opinel.renderOrder = 2

        this.worldGroup.add(this.opinel.instance)
        this.chooseMaterial('bois-1')

        this.opinel.on('alert', (data) => {
            alert(data.message)
        })

        this.opinel.on('opened', this.onOpinelOpenedBound)
        this.opinel.on('closed', this.onOpinelClosedBound)

        if (this.debug.active) {
            const axis = new THREE.AxesHelper(5)
            this.scene.add(axis)
        }

        this.camera.rotateToAzimuth(0, 3)

        this.scene.environmentRotation.y = -2

        const vignette = document.querySelector(`.vignette`)
        vignette.classList.remove('transparent')
        vignette.classList.add('opaque')
        
        const tl = gsap.timeline({
            onComplete: () => {
                const tlenv = gsap.timeline({})
                tlenv.to(this.ground.instance.material, {opacity: 1, ease: "Sine.easeInOut", duration: 2})
                tlenv.to(this.scene, {backgroundBlurriness: 0.04, ease: "Sine.easeInOut", duration: 2}, '<')
                tlenv.to(this.scene.environmentRotation, {y: 0, ease: "Sine.easeInOut", duration: 2})
                
                gsap.delayedCall(1.5, () => {
                    this.initUI()
                })

                this.scene.fog.near = this.camera.instance.position.z * 8
                this.scene.fog.far = this.camera.instance.position.z * 10
            }
        })
        
        tl.to(this.scene.fog, {near: 15, ease: "Sine.easeInOut", duration: 4})
        tl.to(this.scene.fog, {far: 20, ease: "Sine.easeInOut", duration: 4}, '<')
        tl.to(this.scene, {backgroundIntensity: 0.25, ease: "Sine.easeInOut", duration: 4}, '<')

        this.animationLoop.on('update', this.updateBound)
    }

    initUI() {
        this.ui = new UIManager(document.querySelector('.ui'))
        this.ui.on('changeMaterial', this.onChangeMaterialBound)
        this.ui.on('toggleOpenClose', this.onToggleOpenCloseBound)

        this.ui.on('openKnife', this.openKnifeBound)
        this.ui.on('closeKnife', this.closeKnifeBound)

        this.ui.showScreenMenu()

        this.camera.controls.addEventListener('change', () => {
            if (this.ui) {
                this.ui.update()
            }
        });
    
    }

    openKnife() {
        if (!this.opinel.closed) {
            return
        }

        this.opinel.open()
    }
      
    closeKnife() {
        if (this.opinel.closed) {
            return
        }
        
        this.opinel.close()
    }

    toggleKnife() {
        if (this.opinel.closed) {
            this.openKnife()
        }
        else {
            this.closeKnife()
        }
    }

    animateKnifeToFrontSide() {
        this.opinel.rotateToFrontSide()
    }
    
    animateKnifeToBackSide() {
        this.opinel.rotateToBackSide()
    }
    
    setBladeText(text, font) {
        if (this.opinel) {
            this.opinel.setBladeEngravedText(text, font)
        }
    }

    setHandleText(text, font) {
        if (this.opinel) {
            this.opinel.setHandleEngravedText(text, font)
        }
    }

    onChangeMaterial(data) {
        this.chooseMaterial(data.material)
    }

    chooseMaterial(name) {
        this.opinel.useMaterial(name)

        Array.from(document.querySelectorAll(`[data-material]`)).map(e => e.classList.remove("selected"))
        
        const node = document.querySelectorAll(`[data-material='${name}']`)[0]
        node.classList.add("selected")

        document.querySelector(`.material-selector-title`).innerHTML = node.dataset.materialLabel
    }

    onToggleOpenClose() {
        const tl = gsap.timeline({})
        const button = document.querySelector(`.open-close-button`)
        const title = document.querySelector(`.open-close-title`)

        tl.to(button, {css: {opacity: 0}, ease: "Sine.easeInOut", duration: 0.5})
        tl.to(title, {css: {opacity: 0}, ease: "Sine.easeInOut", duration: 0.4}, '<')

        this.opinel.toggleOpenClose()
    }

    onOpinelOpened() {
        document.querySelector(`.open-close-title`).innerHTML = "FERMER"
        
        const tl = gsap.timeline({})
        const button = document.querySelector(`.open-close-button`)
        const title = document.querySelector(`.open-close-title`)

        button.classList.remove("opened")
        button.classList.add("closed")

        tl.to(button, {css: {opacity: 1}, ease: "Sine.easeInOut", duration: 0.5})
        tl.to(title, {css: {opacity: 1}, ease: "Sine.easeInOut", duration: 0.5})
    }
    
    onOpinelClosed() {
        document.querySelector(`.open-close-title`).innerHTML = "OUVRIR"
        
        const tl = gsap.timeline({})
        const button = document.querySelector(`.open-close-button`)
        const title = document.querySelector(`.open-close-title`)

        button.classList.remove("closed")
        button.classList.add("opened")

        tl.to(button, {css: {opacity: 1}, ease: "sine.easeInOut", duration: 0.5})
        tl.to(title, {css: {opacity: 1}, ease: "sine.easeInOut", duration: 0.5})
    }

    rotateOpinelToFrontSide() {       
        this.camera.rotateToAzimuth(0)
        this.camera.rotateToPolar(0)
    }
    
    rotateOpinelToBackSide() {
        this.camera.rotateToAzimuth(Math.PI)
        this.camera.rotateToPolar(0)
    }


    update(event) {
        if (this.worldGroup === null) {
            return
        }

        this.camera.update(event)

        // Camera rotation mode
        // Display the world with camera instance controlled by OrbitControls
        // this.renderer.render(this.scene, this.camera.instance)

        // Object rotation mode
        // Don't use the camera instance. Instead we will move the world group with transposed rotation
        // Render with the fake camera not moving, but supporting the dolly in and out (zoom)
        this.worldGroup.quaternion.copy(this.camera.instance.quaternion.clone().conjugate())
        this.renderer.render(this.scene, this.camera.fake)
    }
    
    destroy() {
        this.lights.destroy()
        this.lights = null

        this.ground.destroy()
        this.ground = null

        this.opinel.destroy()
        this.opinel = null

        Memory.clearScene(this.worldGroup)
        this.worldGroup = null

        this.reg.destroy()
        this.reg = null

        this.envmap.dispose()
        this.envmap = null

        this.updateBound = null
        this.ontouchendBound = null
        this.openKnifeBound = null
        this.closeKnifeBound = null
        this.onOpinelOpenedBound = null
        this.onOpinelClosedBound = null
        this.onToggleOpenCloseBound = null
        this.onChangeMaterialBound = null
        this.assetManagerReadyHandlerBound = null
    }
}